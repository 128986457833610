a {
    color: rgba(68, 199, 103, 1);
}

a:hover {
    color: rgba(68, 199, 103, 0.8);
}

.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: rgba(68, 199, 103, 1);
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
}

@keyframes FadeAnim {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.waves {
    position: relative;
    width: 100%;
    margin-bottom: -7px;
    height: 7vw;
    min-height: 7vw;
}

@keyframes moveForever {
    from {
        transform: translate3d(-90px, 0, 0);
    }
    to {
        transform: translate3d(85px, 0, 0);
    }
}

.parallax {
    animation: moveForever 12s cubic-bezier(0.62, 0.5, 0.38, 0.5) infinite;
    animation-delay: -4s;
}